import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import Button from '@mui/material/Button';
import DropdownOrSimpleButton from '../../DropdownOrSimpleButton';
import ReferralStateTag from '../../ReferralStateTag/ReferralStateTag';
import ReferralConstants from '../../../constants/referrals';

import 'moment/locale/es';
import CommentPanel from './CommentPanel';

const finalStatesOptions = Object.values(ReferralConstants.ReferralStates)
  .filter(({ final }) => !!final)
  .map(({ stateId, name }) => ({ id: `${stateId}`, name }));

const classNames = {
  panel: 'referral-details__panel',
  details: 'referral-details__details',
  detailsTitle: 'referral-details__details__title',
  status: 'referral-details__status',
  statusInfo: 'referral-details__status__info',
  statusActions: 'referral-details__status__actions',
  statusLabel: 'referral-details__status__label',
  statusDropdown: 'referral-details__status__actions__dropdown',
  detail: 'referral-details__detail',
  detailLabel: 'referral-details__detail__label',
  detailValue: 'referral-details__detail__value',
  actions: 'referral-details__actions',
  actionsTitle: 'referral-details__actions__title',
  action: 'referral-details__actions__action',
};

const DetailsPanel = ({ referral }) => {
  const annualEmailAction = referral.details.actions?.find(({ action }) => action.type === 'ANNUAL_EMAIL');
  return (
    <div className={classNames.panel}>
      <div className={classNames.status}>
        <div className={classNames.statusInfo}>
          <span className={classNames.statusLabel}>Estado</span>
          <ReferralStateTag stateId={referral?.stateId} />
        </div>
        <div className={classNames.statusActions}>
          {referral.stateId && !ReferralConstants.stateById(referral.stateId).final && (
            <DropdownOrSimpleButton
              size="large"
              className={classNames.statusDropdown}
              type="primary"
              options={finalStatesOptions}
              onClick={(stateId) => referral.update.finish(Number(stateId))}
            >
              Finalizar
            </DropdownOrSimpleButton>
          )}
          {referral.stateId && ReferralConstants.stateById(referral.stateId).final && (
            <DropdownOrSimpleButton
              size="large"
              className={classNames.statusDropdown}
              type="primary"
              onClick={referral.update.reactivate}
              variant="contained"
            >
              Reactivar
            </DropdownOrSimpleButton>
          )}
        </div>
      </div>
      <div className={classNames.details}>
        <div className={classNames.detailsTitle}>
          Detalles
        </div>
        <div className={classNames.detail}>
          <div className={classNames.detailLabel}>
            Correo
          </div>
          <div className={classNames.detailValue}>
            <dd>{referral?.email}</dd>
          </div>
        </div>
        {
          !!referral?.name && (
            <div className={classNames.detail}>
              <div className={classNames.detailLabel}>
                Nombre
              </div>
              <div className={classNames.detailValue}>
                <dd>{referral.name}</dd>
              </div>
            </div>
          )
        }
        <div className={classNames.detail}>
          <div className={classNames.detailLabel}>
            Fecha de ingreso
          </div>
          <div className={classNames.detailValue}>
            <dd>{moment(referral.createdAt).format('ll')}</dd>
          </div>
        </div>
        <div className={classNames.detail}>
          <div className={classNames.detailLabel}>
            Ultima actualización
          </div>
          <div className={classNames.detailValue}>
            <dd>{moment(referral.updatedAt).format('ll')}</dd>
          </div>
        </div>
      </div>
      {!!annualEmailAction && (
        <div className={classNames.actions}>
          <div className={classNames.actionsTitle}>
            Comunicaciones
          </div>
          <div className={classNames.detail}>
            <div className={classNames.detailLabel}>
              <div className={classNames.action}>
                Descuento de 50% en matrícula
              </div>
            </div>
            <div className={classNames.detailValue}>
              <dd>{moment(annualEmailAction.action.updated_at).format('ll')}</dd>
            </div>
          </div>
        </div>
      )}
      <CommentPanel referral={referral} />
    </div>
  );
};

DetailsPanel.propTypes = {
  referral: PropTypes.shape({
    stateId: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    update: PropTypes.shape({
      state: PropTypes.func,
      finish: PropTypes.func,
      reactivate: PropTypes.func,
    }),
    insert: PropTypes.shape({
      comment: PropTypes.func,
    }),
    details: PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default DetailsPanel;
